import App from "../models/app";
import Category from "../models/category";

type FilterCategoryProps = {
  query: string;
  categoryName: string;
};

export const filterCategories = (
  categories: Category[],
  { query, categoryName }: FilterCategoryProps
): Category[] => {
  if (!query && !categoryName) {
    return categories;
  }

  if (!query) {
    return filterByCategoryName(categories, categoryName);
  }

  if (!categoryName) {
    return filterCategoriesByAppName(categories, query);
  }

  return filterByCategoryNameAndAppName(categories, query, categoryName);
};

const filterByCategoryName = (
  categories: Category[],
  categoryName: string
): Category[] => {
  return categories.filter((category) =>
    category.name.toLowerCase().includes(categoryName.toLowerCase())
  );
};

const filterCategoriesByAppName = (
  categories: Category[],
  query: string
): Category[] => {
  return categories
    .map((category) => {
      const filteredApps = filterAppsByName(category.apps, query);

      if (filteredApps.length > 0) {
        return {
          ...category,
          apps: filteredApps,
        };
      }
    })
    .filter((c) => c);
};

const filterAppsByName = (apps: App[], query: string): App[] => {
  return apps.filter((app) =>
    app.name.toLowerCase().includes(query.toLowerCase())
  );
};

const filterByCategoryNameAndAppName = (
  categories: Category[],
  query: string,
  categoryName: string
): Category[] => {
  const filteredCategories = filterByCategoryName(categories, categoryName);
  return filterCategoriesByAppName(filteredCategories, query);
};
