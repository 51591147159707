import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import Category from "../../models/category";
import { filterCategories } from "../../services/categoriesFilter";
import AppList from "../AppList";

import styles from "./CategoryList.module.css";

const ALL_CATEGORY_NAME = "All";

type CategoryListProps = {
  categories: Category[];
};

export default function CategoryList({ categories }: CategoryListProps) {
  const router = useRouter();
  const { pathname, query } = router;

  const [searchQuery, setSearchQuery] = useState<string>(
    `${query?.query || ""}`
  );

  const [categoryFilter, setCategoryFilter] = useState<string>(
    `${query?.category || ""}`
  );

  const [filteredCategories, setFilteredCategories] = useState<Category[]>(
    categories
  );

  useEffect(() => {
    const result = filterCategories(categories, {
      query: searchQuery,
      categoryName: categoryFilter,
    });

    setFilteredCategories(result);
  }, [categories, searchQuery, categoryFilter]);

  const onQueryChange = (event: any) => {
    setSearchQuery(event.target.value);

    router.push({
      pathname: pathname,
      query: {
        ...query,
        query: event.target.value,
      },
    });
  };

  const onCategoryFilterChange = (event: any) => {
    const newCategoryFilter =
      event.target.value == ALL_CATEGORY_NAME ? "" : event.target.value;

    setCategoryFilter(newCategoryFilter);

    router.push({
      pathname: pathname,
      query: {
        ...query,
        category: newCategoryFilter,
      },
    });
  };

  const categoryOptions = (): string[] => {
    return [ALL_CATEGORY_NAME, ...categories.map((category) => category.name)];
  };

  return (
    <div>
      <div className="row">
        <div className="col-9">
          <input
            type="text"
            className="form-control mb-4 shadow-sm"
            placeholder="Search app by title"
            onChange={onQueryChange}
            defaultValue={searchQuery}
          />
        </div>

        <div className="col-3">
          <select
            className="form-select shadow-sm"
            onChange={onCategoryFilterChange}
            value={categoryFilter}
          >
            {categoryOptions().map((name, index) => (
              <option key={index} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {filteredCategories.map((category, index) => (
        <div key={index} className="mb-5">
          <p className={styles.categoryTitle}>{category.name}</p>

          <AppList apps={category.apps} />
        </div>
      ))}
    </div>
  );
}
