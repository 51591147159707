import App from "../../models/app";
import AppCard from "../AppCard";

type AppListProps = {
  apps: App[];
};

export default function AppList({ apps }: AppListProps) {
  return (
    <div className="row row-cols-1 row-cols-md-3 g-4">
      {apps.map((app, index) => (
        <div key={index} className="col">
          <AppCard app={app} />
        </div>
      ))}
    </div>
  );
}
