import { useRouter } from "next/router";
import { useSession, getSession } from "next-auth/client";

import { getCategories } from "../services/datastore";
import Category from "../models/category";
import CategoryList from "../components/CategoryList";

type IndexProps = {
  categories: Category[];
};

export default function Index({ categories }: IndexProps) {
  const router = useRouter();

  const [session, loading] = useSession();

  if (loading) {
    return null;
  }

  if (!session) {
    router.push("/login");
    return null;
  }

  return (
    <div className="container">
      <CategoryList categories={categories} />
    </div>
  );
}

export async function getServerSideProps(context) {
  const session = await getSession(context);

  let categories = [];
  if (session) {
    categories = await getCategories(session.groups as string[]);
  }

  return {
    props: {
      session: session,
      categories: categories,
    },
  };
}
